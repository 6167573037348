import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SubscriptionPage from './SubscriptionPage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
      <nav className="navbar">
          
        </nav>
        <Routes>
          <Route path="/" element={<SubscriptionPage />} />
          {/* Redirect any unmatched route to the subscription page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
